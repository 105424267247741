import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import api from "../../services/api";


class TotalBoots extends Component {

  constructor(props) {
    super(props);   
    this.state = {     
      boots: [],
      trocas:[],
      user:[],
      total:"0",
      total2:"0",
    };
  }



  async componentDidMount() {

    const user = JSON.parse(localStorage.getItem('user'));
    
    axios.get(`https://sebstoreapi.app.br/api/boots-user/${user._id}`)
    .then((response) => {
      const boots = response.data;
      this.setState({
        boots,        
        total:boots.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
       
    })

    axios.get(`https://sebstoreapi.app.br/api/troca-user/${user._id}`)
    .then((response) => {
      const trocas = response.data;
      this.setState({
        trocas,
        total2:trocas.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
   
    })
    
   }



    render() {

      const { total, total2 } = this.state;

      const bootstotal = total - total2;
   
       
    const user = JSON.parse(localStorage.getItem('user'));
  

  
    return (
    <>
      {bootstotal} 
    </>
  );
}

  }

export default withRouter(TotalBoots);
