
import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiX } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";
import api from "../services/api.js";


// core components
import GerenciadorNavbar from "components/Navbars/GerenciadorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "reactstrap";


class UnidadeAdmin extends Component {

 
  constructor(props) {
    super(props);
    this.handleChangeNome = this.handleChangeNome.bind(this);
    this.handleChangeEndereco = this.handleChangeEndereco.bind(this);
    this.handleChangeNegocio = this.handleChangeNegocio.bind(this);
    this.handleChangeComplemento = this.handleChangeComplemento.bind(this);
    this.handleChangeBairro = this.handleChangeBairro.bind(this);
    this.handleChangeCidade = this.handleChangeCidade.bind(this);    
    this.handleChangeCep = this.handleChangeCep.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.state = {
      unidades_negocios: [],
      unidade_negocio: [],
      unidades: [],
      unidadesInfo: [],
      unidade:[],
      filter:[],
      modal: false,
      modal2: false,
      modal3: false,
      nome:  props.nome,
      status:  props.status,
      id_unidadenegocio: props.id_unidadenegocio,
      endereco: props.endereco,
      complemento: props.complemento,
      bairro: props.bairro,
      cidade: props.cidade,
      cep: props.cep,
      nome2: '',
      selectedPost: null,
      id: null,
    };

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


  toggle2 = i => {
    this.setState({
      modal2: !this.state.modal2,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };

  toggle3 = i => {
    this.setState({
      modal3: !this.state.modal3,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };



  async componentDidMount() {
    axios.get(`https://sebstoreapi.app.br/api/unidade`)
    .then((response) => {
      const unidades = response.data;
      const filter = response.data;
      this.setState({
        unidades,
        filter
      })
    })
      
 

      axios.get(`https://sebstoreapi.app.br/api/unidade-negocio`)
      .then(res => {
        const unidades_negocios = res.data;
        this.setState({ unidades_negocios });
      })
            
  }






handleChangeId = (event) => {
  event.persist();
  this.setState({ id: event.target.value });
}

  handleChange = (event) => {
    event.persist();
    this.setState({ nome: event.target.value });
  }


  handleChangeNome = (event) => {
    event.persist();
    this.setState({ nome: event.target.value });
  }

  handleChange2 = (event) => {
    event.persist();
    this.setState({ nome2: event.target.value });
  }

  

  handleChangeNegocio= (event) => {
    event.persist();
    this.setState({ id_unidadenegocio: event.target.value });
  }

  handleChangeEndereco = (event) => {
    event.persist();
    this.setState({ endereco: event.target.value });
  }

  handleChangeComplemento = (event) => {
    event.persist();
    this.setState({ complemento: event.target.value });
  }

  handleChangeBairro = (event) => {
    event.persist();
    this.setState({ bairro: event.target.value });
  }

  handleChangeCidade = (event) => {
    event.persist();
    this.setState({ cidade: event.target.value });
  }

  handleChangeEstado = (event) => {
    event.persist();
    this.setState({ estado: event.target.value });
  }

  handleChangeCep = (event) => {
    event.persist();
    this.setState({ cep: event.target.value });
  }


  handleChangeStatus= (event) => {
    event.persist();
    this.setState({ status: event.target.value });
  }



  handleSubmit = event => {
    event.preventDefault();
    

    axios.post(`https://sebstoreapi.app.br/api/unidade`, { 
      nome: this.state.nome,
      id_unidadenegocio: this.state.id_unidadenegocio,
      endereco: this.state.endereco,
      complemento: this.state.complemento,
      bairro: this.state.bairro,
      cidade: this.state.cidade,
      estado: this.state.estado,
      cep: this.state.cep,
      status: this.state.status   
    })
    .then(res => {
      alert('Unidade criada com sucesso!');
      this.props.history.push("/unidade-admin");
      window.location.reload();
    })
    .catch(function (error) {
      alert('Ocorreu algum erro!');
      console.log(error);
     });
    }

    handleUpdate = event => {
      event.preventDefault();  

      if (this.state.selectedPost !== null) {
        const unidade = this.state.unidades[this.state.selectedPost];
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://sebstoreapi.app.br/api/unidade/${unidade._id}`, {
        nome: this.state.nome,
        id_unidadenegocio: this.state.id_unidadenegocio,
        endereco: this.state.endereco,
        complemento: this.state.complemento,
        bairro: this.state.bairro,
        cidade: this.state.cidade,
        estado: this.state.estado,
        cep: this.state.cep,
        status: this.state.status      
        }, config)
          .then(res => {
            alert('Unidade editada com sucesso!');
            this.props.history.push("/unidade-admin");
            window.location.reload();
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }

      }


      handleDelete = event => {
        event.preventDefault();  

      if (this.state.selectedPost !== null) {
        const unidade = this.state.unidades[this.state.selectedPost];
        const status2 = "3";
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://sebstoreapi.app.br/api/unidade/${unidade._id}`, {
          status: status2   
        }, config)
          .then(res => {
            alert('Unidade excluída com sucesso!');
            this.props.history.push("/unidade-admin");
            window.location.reload();
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }};

      


    renderUnidades() {
      return this.state.filter.map((unidade, i) => {

         
          return (
            unidade.status === "3" 
            ?
            <tr style={{display:"none"}}></tr>
            : 
            <tr key={unidade._id}>    
            <td> {moment(unidade.createdAt).format('DD/MM/YYYY')}</td>     
            <td style={{fontWeight:200}}> {unidade.id_unidadenegocio.nome}</td>   
            <td style={{fontWeight:400}}> {unidade.nome}</td>    
            <td style={{fontWeight:400}}> 
            
            {unidade.status === "1"
            ?
            <Alert color="success" style={{padding:6, textAlign:"center"}}>
            Ativo
            </Alert>
            : 
            <Alert color="danger" style={{padding:6, textAlign:"center"}}>
            Inativo
            </Alert>
            }
            
            </td> 
            <td><Button
                    size="sm"
                    color="info"
                    onClick={() => this.toggle2(i)}
                    
                    >
                <FiEdit2 />
                  </Button></td> 
                  <td>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => this.toggle3(i)}
                    >
                <FiX />
                  </Button>
                  </td>   
                
            </tr>
          );
      });
  }
  
  renderModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const unidade = this.state.unidades[this.state.selectedPost];
      return (
      
          <Form name="handleUpdate" onSubmit={this.handleUpdate}>
          <ModalHeader><span style={{fontWeight:600}}>Editar Unidade</span></ModalHeader>
          <ModalBody>
          Utilize o formulário para editar informações da Unidade e clique em salvar ou cancelar.
            <br /><br />
            
            <label htmlFor="status"><span style={{fontWeight:600}}>Status</span></label>
            <Input 
type="select"
                  name="status"
                  id="status"
                  required="required"
                  onChange={this.handleChangeStatus}
              
                   
                   >

<option value="1">Selecione...</option>
<option value="1">Ativo</option>
<option value="2">Inativo</option>
                   </Input><br />


<label htmlFor="nome"><span style={{fontWeight:600}}>Unidade</span></label>
                  <Input
                  placeholder={unidade.nome}
                  defaultValue={unidade.nome}
                  value={this.state.nome}
                  name="nome"
                  id="nome"
                  type="text"
                  required="required"
                  onChange={this.handleChangeNome}
                   />
<br />


<label htmlFor="id_unidadenegocio"><span style={{fontWeight:600}}>Unidade de Negócio / {unidade.id_unidadenegocio.nome}</span></label>
        <Input type="select" name="id_unidadenegocio" id="id_unidadenegocio" onChange={this.handleChangeNegocio}>
        <option value={unidade.id_unidadenegocio}>Selecione...</option>




            {this.state.unidades_negocios.map(eventosunidades => (
                eventosunidades.status === "3"
                ?
                <option style={{display:"none"}} key={eventosunidades._id} value={eventosunidades._id}>{eventosunidades.nome}</option>
                : 
                <option key={eventosunidades._id} value={eventosunidades._id}>{eventosunidades.nome}</option>
             ))}      
             
                
        </Input>
        <br />

       
        <FormGroup>
          <Label for="endereco"><span style={{fontWeight:600}}>Endereço</span></Label>
          <Input 
          type="text" 
          id="endereco" 
          required="required" 
          onChange={this.handleChangeEndereco} 
          placeholder={unidade.endereco}
          defaultValue={unidade.endereco}
          value={this.state.endereco} 
          />
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
          <Label for="complemento"><span style={{fontWeight:600}}>Complemento</span></Label>
          <Input 
          type="text" 
          id="complemento" 
          name="complemento"
          onChange={this.handleChangeComplemento} 
          placeholder={unidade.complemento}
          defaultValue={unidade.complemento}
          value={this.state.complemento} 
          />
          </FormGroup>
          <FormGroup className="col-md-6">
          <Label for="bairro"><span style={{fontWeight:600}}>Bairro</span></Label>
          <Input 
          type="text" 
          id="bairro" 
          name="bairro"
          required="required" 
          onChange={this.handleChangeBairro} 
          placeholder={unidade.bairro}
          defaultValue={unidade.bairro}
          value={this.state.bairro} 
          />
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="cidade"><span style={{fontWeight:600}}>Cidade</span></Label>
            <Input 
          type="text" 
          id="cidade" 
          name="cidade"
          required="required"           
          onChange={this.handleChangeCidade} 
          placeholder={unidade.cidade}
          defaultValue={unidade.cidade}
          value={this.state.cidade} 
          />
          </FormGroup>
          <FormGroup className="col-md-4">
            <Label for="estado"><span style={{fontWeight:600}}>Estado / {unidade.estado}</span></Label>
            <Input type="select" name="estado" id="estado" onChange={this.handleChangeEstado} >
            <option value={unidade.estado}>Selecione...</option>
    <option value="AC">Acre</option>
    <option value="AL">Alagoas</option>
    <option value="AP">Amapá</option>
    <option value="AM">Amazonas</option>
    <option value="BA">Bahia</option>
    <option value="CE">Ceará</option>
    <option value="DF">Distrito Federal</option>
    <option value="ES">Espirito Santo</option>
    <option value="GO">Goiás</option>
    <option value="MA">Maranhão</option>
    <option value="MS">Mato Grosso do Sul</option>
    <option value="MT">Mato Grosso</option>
    <option value="MG">Minas Gerais</option>
    <option value="PA">Pará</option>
    <option value="PB">Paraíba</option>
    <option value="PR">Paraná</option>
    <option value="PE">Pernambuco</option>
    <option value="PI">Piauí</option>
    <option value="RJ">Rio de Janeiro</option>
    <option value="RN">Rio Grande do Norte</option>
    <option value="RS">Rio Grande do Sul</option>
    <option value="RO">Rondônia</option>
    <option value="RR">Roraima</option>
    <option value="SC">Santa Catarina</option>
    <option value="SP">São Paulo</option>
    <option value="SE">Sergipe</option>
    <option value="TO">Tocantins</option>
            </Input>
          </FormGroup>
          <FormGroup className="col-md-2">
            <Label for="cep"><span style={{fontWeight:600}}>CEP</span></Label>
            <Input 
          type="text" 
          id="cep" 
          name="cep"
          required="required" 
          onChange={this.handleChangeCep} 
          placeholder={unidade.cep}
          defaultValue={unidade.cep}
          value={this.state.cep} 
          />
          </FormGroup>
        </div>
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" href="/unidade-admin">Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  renderModal2 = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const unidade = this.state.unidades[this.state.selectedPost];
      return (
      
          <Form name="handleDelete" onSubmit={this.handleDelete}>
          <ModalHeader><span style={{fontWeight:600}}>Excluir Unidade</span></ModalHeader>
          <ModalBody>
          Confirme a exclusão da Unidade clicando em excluir ou cancelar.
            <br /><br />
            
           <label htmlFor="nome"><span style={{fontWeight:600}}>{unidade.nome}</span></label>             
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="danger" type="submit">Excluir</Button>{' '}
            <Button outline size="sm" color="danger" href="/unidade-admin">Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  render() {

    const { modal2, unidades } = this.state;


    const requestSearch = (searchedVal) => {
      const filteredRows = unidades.filter((row) => {
          return row.nome.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
      });
      if (searchedVal.length < 1) {
          this.setState({filter: unidades});
      }
      else {
        this.setState({filter: filteredRows});
      }
    };

  return (
    <>
      <GerenciadorNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
                <div style={{fontWeight:600, marginTop:50}}>
            <h1>Unidades</h1>
          </div>

          <br />


          <Row>
            <Col sm style={{textAlign:"right"}}>
 <Button
                size="sm"
                color="success"
                onClick={this.toggle}
                >
            nova unidade
              </Button>       
           
            </Col>
          </Row>

          <br />

          <form>
<Row style={{marginTop:0}}>
<Col>    


<InputGroup>
<InputGroupAddon addonType="prepend">
<InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
</InputGroupAddon>
<Input type="search" placeholder="Digite o nome da unidade..." onChange={(e) => requestSearch(e.target.value)} style={{width:"90%"}}  />
</InputGroup>



</Col>                   
</Row>
</form>

<br />
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>Nova Unidade</span></ModalHeader>
          <ModalBody>
            Preencha o formulário com os dados da Unidade e clique em salvar ou cancelar.
            <br /><br />

            <label htmlFor="status"><span style={{fontWeight:600}}>Status</span></label>
            <Input 
type="select"
                  name="status"
                  id="status"
                  required="required"
                  onChange={this.handleChangeStatus}
              
                   
                   >

<option value="1">Selecione...</option>
<option value="1">Ativo</option>
<option value="2">Inativo</option>
                   </Input><br />
            
                  <label htmlFor="nome"><span style={{fontWeight:600}}>Unidade</span></label>
                  <Input 
                  placeholder="Digite um nome..."
                  name="nome"
                  id="nome"
                  type="text"
                  required="required"
                  onChange={this.handleChange}
                   />
<br />


<label htmlFor="id_unidadenegocio"><span style={{fontWeight:600}}>Unidade de Negócio</span></label>
        <Input type="select" name="id_unidadenegocio" id="id_unidadenegocio" onChange={this.handleChangeNegocio}>
        <option value={this.state.unidades_negocios._id}>Selecione...</option>
        {this.state.unidades_negocios.map(eventosunidades => (
                eventosunidades.status === "3" 
                ?
                <option style={{display:"none"}} key={eventosunidades._id} value={eventosunidades._id}>{eventosunidades.nome}</option>
                : 
                <option key={eventosunidades._id} value={eventosunidades._id}>{eventosunidades.nome}</option>
             ))}      
        </Input>
        <br />

       
        <FormGroup>
          <Label for="endereco"><span style={{fontWeight:600}}>Endereço</span></Label>
          <Input 
          type="text" 
          id="endereco" 
          required="required" 
          onChange={this.handleChangeEndereco} 
          placeholder="Rua Francisco Ferrari, 1987"/>
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
          <Label for="complemento"><span style={{fontWeight:600}}>Complemento</span></Label>
          <Input 
          type="text" 
          id="complemento" 
          name="complemento"
          onChange={this.handleChangeComplemento} 
          placeholder="Perto do mercado..."/>
          </FormGroup>
          <FormGroup className="col-md-6">
          <Label for="bairro"><span style={{fontWeight:600}}>Bairro</span></Label>
          <Input 
          type="text" 
          id="bairro" 
          required="required" 
          onChange={this.handleChangeBairro} 
          />
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="cidade"><span style={{fontWeight:600}}>Cidade</span></Label>
            <Input 
          type="text" 
          id="cidade" 
          name="cidade"
          required="required" 
          onChange={this.handleChangeCidade} 
          />
          </FormGroup>
          <FormGroup className="col-md-4">
            <Label for="estado"><span style={{fontWeight:600}}>Estado</span></Label>
            <Input type="select" name="estado" id="estado" onChange={this.handleChangeEstado} >
            <option value="">Selecione...</option>
    <option value="AC">Acre</option>
    <option value="AL">Alagoas</option>
    <option value="AP">Amapá</option>
    <option value="AM">Amazonas</option>
    <option value="BA">Bahia</option>
    <option value="CE">Ceará</option>
    <option value="DF">Distrito Federal</option>
    <option value="ES">Espirito Santo</option>
    <option value="GO">Goiás</option>
    <option value="MA">Maranhão</option>
    <option value="MS">Mato Grosso do Sul</option>
    <option value="MT">Mato Grosso</option>
    <option value="MG">Minas Gerais</option>
    <option value="PA">Pará</option>
    <option value="PB">Paraíba</option>
    <option value="PR">Paraná</option>
    <option value="PE">Pernambuco</option>
    <option value="PI">Piauí</option>
    <option value="RJ">Rio de Janeiro</option>
    <option value="RN">Rio Grande do Norte</option>
    <option value="RS">Rio Grande do Sul</option>
    <option value="RO">Rondônia</option>
    <option value="RR">Roraima</option>
    <option value="SC">Santa Catarina</option>
    <option value="SP">São Paulo</option>
    <option value="SE">Sergipe</option>
    <option value="TO">Tocantins</option>
            </Input>
          </FormGroup>
          <FormGroup className="col-md-2">
            <Label for="cep"><span style={{fontWeight:600}}>CEP</span></Label>
            <Input 
          type="text" 
          id="cep" 
          name="cep"
          required="required" 
          onChange={this.handleChangeCep} 
          />
          </FormGroup>
        </div>
        
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>




        <Modal isOpen={this.state.modal2} className="modal-lg">
        {this.renderModal(this.state.unidades[this.state.selectedPost])}
        </Modal>

        <Modal isOpen={this.state.modal3} className={this.props.className}>
        {this.renderModal2(this.state.unidades[this.state.selectedPost])}
        </Modal>


        

          <Table striped>
          <thead>
        <tr>
         
          <th width="10%">Data</th>
          <th width="25%">Unidade de Negócio</th>  
          <th width="45%">Unidade</th> 
          <th width="10%">Status</th>  
          <th width="5%">&nbsp;</th>  
          <th width="5%">&nbsp;</th>   
        </tr>
      </thead>


      <tbody>
   {this.renderUnidades()}
        
      </tbody>
    </Table>
<hr />


      
      </Container>
     
        <DemoFooter />
        </div>
      </div>
    </>
  );
}}

export default withRouter(UnidadeAdmin);
