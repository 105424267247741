import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiX } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import api from "../../services/api.js";
import TotalBoots from "components/Headers/TotalBoots.js";


// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Alert,
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  FormGroup,
  Label
} from "reactstrap";


class SectionMinhasCompras extends Component {

 
  constructor(props) {
    super(props);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.state = {     
      boots: [],
      trocas:[],
      tipoboots: {},
      produto:{},
      modal: false,
      modal2: false,
      total:"0",
      total2:"0",
      status:  props.status,
      selectedPost: null,
    };

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);

  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggle2 = i => {
    this.setState({
      modal2: !this.state.modal2,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };


  handleChangeStatus = (event) => {
    event.persist();
    this.setState({ status: event.target.value });
  }


   async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    
    axios.get(`https://sebstoreapi.app.br/api/boots-user/${user._id}`)
    .then((response) => {
      const boots = response.data;
      const tipoboots = response.data.tipoboots;
      this.setState({
        boots,
        tipoboots,
        total:boots.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)
      })
       
    })     
    
    
    axios.get(`https://sebstoreapi.app.br/api/troca-user/${user._id}`)
    .then((response) => {
      const trocas = response.data;
      const produto = response.data.produto;
      this.setState({
        produto,
        trocas,
        total2:trocas.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
      
   
    })

  }


  handleUpdate = event => {
    event.preventDefault();  

    if (this.state.selectedPost !== null) {
      const troca = this.state.trocas[this.state.selectedPost];
      const config = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
      };
      console.log(troca._id);
      axios.put(`https://sebstoreapi.app.br/api/troca/${troca._id}`, {
      status: "4"      
      }, config)
        .then(res => {
          alert('Recebimento confirmado com sucesso!');
          this.props.history.push("/minhas-trocas");
          window.location.reload();
        })
        .catch(function (error) {
          alert('Ocorreu algum erro!');
          console.log(error);
      });
    }

    }


    renderUnidades() {
      return this.state.trocas.map((troca, i) => {

         
          return (
            <tr key={troca._id}>    
            <td> {moment(troca.createdAt).format('DD/MM/YYYY')}</td>     
            <td style={{fontWeight:600}}> {troca.produto.nome}</td>               
            <td style={{fontWeight:600, color:"#F5593D"}}>  - {troca.boots} </td> 
            <td style={{fontWeight:400}}>
           
            {(() => {
        switch (troca.status) {
          case '0':
            return <Alert color="danger" size="sm" style={{padding:6, color:"#fff", textAlign:"center"}}> Cancelado </Alert>
          case '1':
            return <Alert color="warning" size="sm" style={{padding:6,  color:"#000", textAlign:"center"}}> Aguardando confirmação </Alert>
          case '2':
            return <Alert color="primary" size="sm" style={{padding:6,  color:"#000", textAlign:"center"}}> Confirmado</Alert>
          case '3':
            return <Alert color="success" size="sm" style={{padding:6,  color:"#fff", textAlign:"center"}}> Enviado</Alert>
          case '4':
            return <Alert  size="sm" style={{padding:6,  color:"#fff", textAlign:"center", backgroundColor:"#666"}}> Entregue</Alert>
          default:
            return null
        }
      })()}
            
            </td> 
            <td>
                  
                  
                  
                  
        {(() => {
        switch (troca.status) {         
          case '3':
            return <Button size="sm" color="secundary" onClick={() => this.toggle2(i)}>Confirmar Entrega</Button>
          case '4':
            return <Alert size="sm" style={{padding:6, backgroundColor:"#666", color:"#fff", textAlign:"center"}}> OK</Alert>
          default:
            return null
        }
      })()}
      
      </td> 
            <td>
                  <Button size="sm" color="secundary"
                    href={`/troca-select/${troca._id}`}
                    >
                <FiEye />
                  </Button>
                  </td> 
            </tr>
          );
      });
  }
  
  renderModal2 = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const troca = this.state.trocas[this.state.selectedPost];


      return (
      
          <Form name="handleUpdate" onSubmit={this.handleUpdate}>
          <ModalHeader><span style={{fontWeight:600}}>Confirmação de Entrega de Produto</span></ModalHeader>
          <ModalBody>
                              
               
        
        <br />
        <span style={{fontWeight:400}}>
        Olá! Confirme o recebimento do seu produto <strong>"{troca.produto.nome}"</strong>.Esse produto foi entregue? 
        </span>
     
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Sim</Button>{' '}
            <Button size="sm" color="danger" href="/minhas-trocas">Não</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }
 

  render() {

    const { total, total2, trocas } = this.state;

  return (
    <>
      <IndexNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
      <div style={{fontWeight:400,marginTop:60}}>
            <a href="/index" style={{fontWeight:600,}}>Página Principal</a> | Trocas
          </div>
         
          <Row style={{marginTop:0}}>
              <Col sm>
              <div style={{fontWeight:600,}}>
            <h1>Trocas</h1>
          </div>
                </Col>
                <Col sm>
              <div style={{fontWeight:600, fontSize:20}}>
              Tokens trocados<br /><span style={{fontWeight:600, fontSize:50, color:"#F5593D"}}>-  {total2} Tokens</span>
          </div>
                </Col>
                </Row>


          <br />
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>Novo Produto</span></ModalHeader>
          <ModalBody>
            Preencha o formulário com os dados da Unidade e clique em salvar ou cancelar.
            <br /><br />
            
                 

            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>

<Modal isOpen={this.state.modal2} className="modal-lg">
        {this.renderModal2(this.state.trocas[this.state.selectedPost])}
        </Modal>
   

          <Table striped>
          <thead>
        <tr>
         
          <th>Data</th>
          <th>Produto</th>  
          <th>Tokens</th>  
          <th>Status</th>  
          <th>Recebeu seu produto?</th>  
          <th>&nbsp;</th> 
        </tr>
      </thead>


      <tbody>
   {this.renderUnidades()}
        
      </tbody>
    </Table>
<hr />


      
      </Container>
     

        </div>
      </div>
    </>
  );
}}

export default withRouter(SectionMinhasCompras);
