/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Chart } from "react-google-charts";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CustomInput,
  Card,
} from "reactstrap";


function Gerenciador() {


  const data = [
    ["Cat. 1", "", { role: "style" }],
    ["Cat. 2", 8.94, "#000"], // RGB value
    ["Cat. 3", 10.49, "silver"], // English color name
    ["Cat. 4", 19.3, "#FF9D72"],
    ["Cat. 5", 21.45, "color: #e5e4e2"], // CSS-style declaration
  ];
  
  const options = {
    chart: {
      title: "Saldo Boot's",
      subtitle: "Sales, Expenses, and Profit: 2014-2017",  
    },
  };
 

  const dataOld = [
    ["Name", "Popularity"],
    ["Cesar", 250],
    ["Rachel", 4200],
    ["Patrick", 2900],
    ["Eric", 8200],
  ];
  
  const dataNew = [
    ["Name", "Popularity"],
    ["Cesar", 370],
    ["Rachel", 600],
    ["Patrick", 700],
    ["Eric", 1500],
  ];
  
  const diffdata = {
    old: dataOld,
    new: dataNew,
  };
  

  React.useEffect(() => {

   
  }, []);
  return (
    <>
      <div className="section section-buttons">
        <Container>

      

          <div style={{fontWeight:600, marginTop:50}}>
            <h1>Dashboard</h1>
          </div>

        <hr />


<Row>
<Col sm>
    <Card>
      <div style={{padding:20, fontSize:20}}>
        Título do Indicador     
      </div>
      <Chart chartType="ColumnChart" width="100%" height="400px" data={data} />
    </Card>
  </Col>
  <Col sm>
  <Card>
      <div style={{padding:20, fontSize:20}}>
        Título do Indicador     
      </div>
      <Chart chartType="ColumnChart" width="100%" height="400px" data={data} />
    </Card>
  </Col>
  <Col sm>
  <Card>
      <div style={{padding:20, fontSize:20}}>
        Título do Indicador     
      </div>
      <Chart chartType="ColumnChart" width="100%" height="400px" data={data} />
    </Card>
  </Col>
</Row>
 

        </Container>
      </div>
    </>
  );
}

export default Gerenciador;
