import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiX } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";
import api from "../../services/api.js";
import TotalBoots from "components/Headers/TotalBoots.js";


// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Alert,
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  FormGroup,
  Label
} from "reactstrap";


class SectionBoots extends Component {

 
  constructor(props) {
    super(props);
    this.state = {     
      boots: [],
      trocas:[],
      tipoboots: {},
      modal: false,
      total:"0",
      total2:"0",
    };

    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


   async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    
    axios.get(`https://sebstoreapi.app.br/api/boots-user/${user._id}`)
    .then((response) => {
      const boots = response.data;
      const tipoboots = response.data.tipoboots;
      this.setState({
        boots,
        tipoboots,
        total:boots.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)
      })
       
    })     
    
    
    axios.get(`https://sebstoreapi.app.br/api/troca-user/${user._id}`)
    .then((response) => {
      const trocas = response.data;
      this.setState({
        trocas,
        total2:trocas.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
   
    })

  }


    renderUnidades() {
      return this.state.boots.map((boot, i) => {

         
          return (
            <tr key={boot._id}>    
            <td> {moment(boot.createdAt).format('DD/MM/YYYY')}</td>     
            <td style={{fontWeight:600}}> {boot.tipoboots.nome}</td>   
            <td style={{fontWeight:200}}> {boot.tipoboots.desc}</td>  
            <td style={{fontWeight:600}}> {boot.boots} </td> 
            </tr>
          );
      });
  }
  
 
 

  render() {

    const { total, total2 } = this.state;

  return (
    <>
      <IndexNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
      <div style={{fontWeight:400,marginTop:60}}>
            <a href="/index" style={{fontWeight:600,}}>Página Principal</a> | Meus Tokens
          </div>
         
          <Row style={{marginTop:0}}>
              <Col sm>
              <div style={{fontWeight:600,}}>
            <h1>Meus Tokens</h1>
          </div>
                </Col>
                <Col sm>
              <div style={{fontWeight:600, fontSize:20}}>
              <span style={{color:"#51BCDA"}}>{total} Tokens</span> <span style={{color:"#F5593D"}}>- {total2} Trocas</span><br /><span style={{fontWeight:600, fontSize:50, color:"#51BCDA"}}>= <TotalBoots /> Tokens</span>
          </div>
                </Col>
                </Row>


          <br />
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>Novo Produto</span></ModalHeader>
          <ModalBody>
            Preencha o formulário com os dados da Unidade e clique em salvar ou cancelar.
            <br /><br />
            
                 

            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>


   

          <Table striped>
          <thead>
        <tr>
         
          <th>Data</th>
          <th>Tipo de Recompensa</th>  
          <th>Descrição</th>  
          <th>Tokens</th>   
        </tr>
      </thead>


      <tbody>
   {this.renderUnidades()}
        
      </tbody>
    </Table>
<hr />


      
      </Container>
     

        </div>
      </div>
    </>
  );
}}

export default withRouter(SectionBoots);
