import React, { useEffect, useState } from "react";
import axios from 'axios';
import classnames from "classnames";

import { SlMagnifier } from "react-icons/sl";

import { SlQuestion } from "react-icons/sl";
import { SlPower } from "react-icons/sl";
import api from "../../services/api";


// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,  
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal
} from "reactstrap";

import logo from "../../assets/img/logo.png";
import { getToken } from "../../services/auth";


function IndexNavbar() {
  const [largeModal, setLargeModal] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState("#FFF");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [categorias, setCategorias] = useState([]);
  

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const [user, setUser] = React.useState(() => {
    // getting stored value
    const saved = localStorage.getItem("user");

    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });



  React.useEffect(() => {
    const token = getToken();
    const headers = { Authorization: `Bearer ${token}`};
    
    console.log(token);
    const fetchData = async () => {
        const result = await axios(
            'https://sebstoreapi.app.br/api/categoria/', 
            {
              headers
                }
             );
        setCategorias(result.data);
    }
    fetchData()
    
}, []);



  

  


  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/index"
            title="SEB Store"
          >
            
          <img src={logo} style={{height:40}} />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
            <UncontrolledDropdown>
        <DropdownToggle
          aria-expanded={false}
          aria-haspopup={true}
          caret
          className="btn-round"
          color="secondary"
          data-toggle="dropdown"
          id="dropdownMenuButton"
          type="button"
        >
          Categorias de produtos
        </DropdownToggle>
        <DropdownMenu aria-labelledby="dropdownMenuButton">

        {categorias.map(categoria => (  
           categoria.status === "3" 
           ?
           <DropdownItem style={{display:"none"}}>
            &nbsp;
            </DropdownItem>
           :       
            <DropdownItem key={categoria._id} href={`/categoria/${categoria._id}`}>
            <div>{categoria.nome}</div>
            </DropdownItem>
             ))}          
     
        </DropdownMenu>
      </UncontrolledDropdown>
            </NavItem>
            
           

            <NavItem>
              <Button
                className="btn-round"
                color="danger"
                href="/minhas-trocas"
                  >
                Trocas
              </Button>
            </NavItem>
            
           
            <NavItem>
              <Button
                className="btn-round"
                color="info"
                href="/boots"
              >
               Tokens
              </Button>
            </NavItem>

            <NavItem>
              <Button
                className="btn-round"
                color="success"
                href="/vouchers-s"
              >
         Vouchers
              </Button>
            </NavItem>


           
            <NavItem>
              <NavLink
                data-placement="bottom"
                style={{cursor:"pointer"}}
                href="/busca"
              >
                <SlMagnifier />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
              style={{cursor:"pointer"}}
                data-placement="bottom"
                onClick={() => setLargeModal(true)}
              >
                <SlQuestion />
              </NavLink>
            </NavItem>
            <NavItem>
           
              <NavLink
                data-placement="bottom"
                href="/sair"
              >
                <SlPower />
              </NavLink>
            </NavItem>


            {user.tipo === "1"
            ?
            <NavItem>
              <Button
              className="btn-round"
              color="info"
              href="/gerenciador"
              target="_Blank"
              >
              Gerenciador
              </Button>
            </NavItem>
            : 
            <span></span>
            }

            



          </Nav>
        </Collapse>
        <Modal
        isOpen={largeModal}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal(false)}
      >
        <div className="modal-header">
          <h1 className="modal-title" style={{fontWeight:600}} id="myLargeModalLabel">
            Precisando de Ajuda?
          </h1>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setLargeModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body" style={{fontWeight:"400"}}>

        Bem-vindo ao SEB Store, o marketplace oficial do Grupo SEB! Aqui, oferecemos a você uma experiência única de recompensas. Ao acessar nossa plataforma com seus dados de acesso corporativo, você receberá incríveis 80 Tokens de Onboarding para trocar por produtos exclusivos.<br /><br />

Nossa plataforma é fácil de usar e intuitiva. Tudo o que você precisa fazer é escolher o produto que deseja, clicar em "Trocar Tokens" e selecionar a unidade na qual gostaria de receber seu prêmio. Depois disso, é só acompanhar o status da troca.<br /><br />

Estamos empolgados em tê-lo conosco no SEB Store e esperamos que você desfrute das recompensas que preparamos para você. Comece a explorar agora e troque seus Tokens por produtos incríveis!<br /><br />&nbsp;
        </div>
      </Modal>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
